import React, { useEffect, useRef, useState } from "react";
import UncontrolledExample from "./carousel";
import { backend, mediaurl, producturl } from "../../../../path";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../../components/whatsapp connect";
import { Rating } from "@mui/material";
import { Helmet } from "react-helmet";
import LoaderMain from "../../../layout/header/loader_sub";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi2";
import { PiShoppingCartThin } from "react-icons/pi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { CiHeart } from "react-icons/ci";
import { VscHeartFilled } from "react-icons/vsc";
import { RxPadding } from "react-icons/rx";
import StarIcon from "@mui/icons-material/Star";
import { trimDescriptionToTen } from "../../../../utils/trimDescription";
import TopSellingComponent from "../../topselling/Index";

// import { Rating } from "react-simple-star-rating";

function HomepageNew(props) {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    fade: true,
    waitForAnimate: false,
  };
  const Topsellingsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Topsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    className: "slider variable-width",
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handlePrev = () => {
    console.log("clicked");

    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  // useEffect(()=>{
  //   let userdata = JSON.parse(localStorage.getItem('userdata'));
  //   fetch(backend + "tokentest", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:`Bearer ${userdata.access_token}`,
  //       "Content-Type": "application/json",
  //     }
  //   })
  //     .then((response) => {
  //       response.json().then((data) => {
  //       console.log(data)

  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error during login:", error);
  //     });
  // },[])
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [subEmail, setSubEmail] = useState("");

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const fetchData = () => {
    setLoading(true);

    fetch(backend + "getallspecialproduts", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    fetch(backend + "addsubscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: subEmail }),
    })
      .then((response) => {
        setSubEmail("");
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          toast.success("Subscribed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const heroImages = [
    {
      id: 1,
      src: "/storage/general/HANDLOOM.jpg",
      mob_src: "/storage/general/mobile banner-01.jpg",
      route: "/category/handlooms",
      bgColor: "#AD4A0E",
    },
    {
      id: 2,
      src: "/storage/general/Main Banner 500.jpg",
      mob_src: "/storage/general/mobile banner-02.jpg",
      route: "/category/ayurveda",
      bgColor: "#224F01",
    },
  ];
  function getFirstImageUrl(data) {
    const producturl = "https://keralaspecial.in/server/public/upload/products";

    // Check if data is a valid string
    if (typeof data !== "string" || !data.trim()) {
      console.error("Invalid data provided:", data);
      return null; // Return null or an appropriate default value
    }

    try {
      // Use a regular expression to extract all strings inside double quotes
      let matches = data
        .match(/"([^"]*)"/g)
        ?.map((item) => item.replace(/"/g, ""));

      // Check if matches exist and get the first value
      if (matches && matches.length > 0) {
        let firstImage = matches[0];
        // Join the base URL with the first image filename
        return `${producturl}/${firstImage}`;
      } else {
        console.error("No matches found in data:", data);
        return null;
      }
    } catch (error) {
      console.error("Error processing data:", error);
      return null;
    }
  }
  const scrollDown = () => {
    //function to scroll down 100px
    window.scrollBy(0, 1000);
  };
  function trimText(text, maxLength = 35) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }
  const handleAyurvedha = () => {
    navigate("/category/ayurveda");
  };
  const handleProduct = () => {
    navigate("/category/handlooms");
  };
  console.log("data", data);
  return (
    <>
      {loading && <LoaderMain />}

      <Helmet>
        <title>Best online products in kerala</title>
        <meta
          name="description"
          content="Discover Kerala's finest products online! Handicrafts, spices, Ayurveda, handlooms & more. Fast & reliable shipping."
        />
        <meta
          name="keywords"
          content="online shopping,online shopping sites,online store"
        />
      </Helmet>
      <main>
        {/* ----------------------------------------------------- */}
        {/* Hero section start */}
        <Hero>
          <div className="slider-container">
            <Slider {...settings}>
              {heroImages.map((data, index) => {
                return (
                  <Slide key={data.id} className="hero">
                    <ImageContainer className="hero">
                      <img src={data.src} alt="Image not found" />
                    </ImageContainer>
                    <button style={{ background: data.bgColor }}>
                      <a href={data.route}>Shop Now</a>
                      <span>
                        <HiOutlineArrowRight />
                      </span>
                    </button>
                  </Slide>
                );
              })}
            </Slider>
          </div>
        </Hero>
        <MobileHero>
          <div className="slider-container x">
            <Slider {...settings}>
              {heroImages.map((data, index) => {
                return (
                  <MobileSlide key={data.id} className="hero x">
                    <ImageContainer className="hero">
                      <img src={data.mob_src} alt="Image not found" />
                    </ImageContainer>
                    <button style={{ background: data.bgColor }}>
                      <a href={data.route}>Shop Now</a>
                      <span>
                        <HiOutlineArrowRight />
                      </span>
                    </button>
                  </MobileSlide>
                );
              })}
            </Slider>
          </div>
        </MobileHero>
        {/* Hero section end */}
        {/* ----------------------------------------------------- */}
        {/* Experience section start */}
        <Experience className="top">
          <div className="wrapper">
            <Container className="experience">
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/delivery.svg" alt="delivery" />
                </ImageContainer>
                <h5>Home Delivery</h5>
              </Section>
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/experience.svg" alt="experience" />
                </ImageContainer>
                <h5>7+ Years Experience</h5>
              </Section>
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/people.svg" alt="people" />
                </ImageContainer>
                <h5>Customer Satisfaction</h5>
              </Section>
            </Container>
          </div>
        </Experience>
        {/* Experience section end */}
        {/* ----------------------------------------------------- */}

        {/* Deals section start */}
        <section>
          <div className="wrapper">
            <h4 style={{ fontWeight: 500 }}>Deals of the Day</h4>
            <Deals>
              <Left className="deals-left">
                {data &&
                  data.getDealOfTheDay &&
                  data.getDealOfTheDay.slice(1, 7).map((item, index) => {
                    return (
                      <Card key={item.product_id}>
                        <Top>
                          <Like
                            onClick={() => handleAddToWishList(item.product_id)}
                          >
                            <CiHeart />
                          </Like>
                          <CardImageContainer
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            <img
                              src={producturl + item.thumbnail}
                              alt="Image not found"
                            />
                          </CardImageContainer>
                        </Top>
                        <Bottom className="withWhite">
                          <p
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            {item.product_name}
                          </p>
                          <Rating
                            name="hover-feedback"
                            value={item.average_stars}
                            precision={0.5}
                            // getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <DescriptionButton>
                            <h3>₹ {item.sale_price}</h3>
                            <CartBtn>
                              <button
                                className="green"
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <PiShoppingCartThin />
                              </button>
                            </CartBtn>
                          </DescriptionButton>
                        </Bottom>
                      </Card>
                    );
                  })}
              </Left>
              <Right>
                {" "}
                <Card>
                  <Top>
                    <Like
                      onClick={() =>
                        handleAddToWishList(
                          data &&
                            data.getDealOfTheDay &&
                            data.getDealOfTheDay[0].product_id
                        )
                      }
                    >
                      <CiHeart />
                    </Like>
                    <CardImageContainer
                      onClick={() =>
                        handleProductRoute(
                          data &&
                            data.getDealOfTheDay &&
                            data.getDealOfTheDay[0].product_slug
                        )
                      }
                    >
                      <img
                        src={getFirstImageUrl(
                          data.getDealOfTheDay && data.getDealOfTheDay[0].images
                        )}
                        alt="Image not found"
                      />
                    </CardImageContainer>
                  </Top>
                  <Bottom className="withbg">
                    <p
                      onClick={() =>
                        handleProductRoute(
                          data &&
                            data.getDealOfTheDay &&
                            data.getDealOfTheDay[0].product_slug
                        )
                      }
                    >
                      {data &&
                        data.getDealOfTheDay &&
                        data.getDealOfTheDay[0].product_name}
                    </p>
                    <Rating
                      name="hover-feedback"
                      value={
                        data &&
                        data.getDealOfTheDay &&
                        data.getDealOfTheDay[0].average_stars
                      }
                      precision={0.5}
                      // getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    <DescriptionButton>
                      <h3>
                        ₹{" "}
                        {data &&
                          data.getDealOfTheDay &&
                          data.getDealOfTheDay[0].sale_price}
                      </h3>
                      <CartBtn>
                        <button
                          onClick={() =>
                            handleAddCart(
                              data &&
                                data.getDealOfTheDay &&
                                data.getDealOfTheDay[0].product_id
                            )
                          }
                        >
                          <PiShoppingCartThin />
                        </button>
                      </CartBtn>
                    </DescriptionButton>
                  </Bottom>
                </Card>
              </Right>
            </Deals>
          </div>
          <MobileDeals>
            <div className="slider-container">
              <Slider {...Topsettings}>
                {data &&
                  data.getDealOfTheDay &&
                  data.getDealOfTheDay.map((item, index) => {
                    return (
                      <Card key={item.product_id}>
                        <Top>
                          <Like
                            onClick={() => handleAddToWishList(item.product_id)}
                          >
                            <CiHeart />
                          </Like>
                          <CardImageContainer
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            <img
                              src={producturl + item.thumbnail}
                              alt="Image not found"
                            />
                          </CardImageContainer>
                        </Top>
                        <Bottom className="withWhite">
                          <p
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            {trimText(item.product_name)}
                          </p>
                          <Rating
                            name="hover-feedback"
                            value={item.average_stars}
                            precision={0.5}
                            // getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <DescriptionButton>
                            <h3>₹ {item.sale_price}</h3>
                            <CartBtn>
                              <button
                                className="green"
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <PiShoppingCartThin />
                              </button>
                            </CartBtn>
                          </DescriptionButton>
                        </Bottom>
                      </Card>
                    );
                  })}
              </Slider>
            </div>
          </MobileDeals>
        </section>
        {/* Deals section end */}
        {/* ----------------------------------------------------- */}
        {/* Popular section start */}
        <Popular>
          <div className="wrapper">
            <h2>Popular Products</h2>
            <PopularInner>
              <Right>
                {" "}
                <Card className="fullheight">
                  <Top>
                    <Like
                      onClick={() =>
                        handleAddToWishList(
                          data &&
                            data.getPopularProduct &&
                            data.getPopularProduct[0].product_id
                        )
                      }
                    >
                      <CiHeart />
                    </Like>
                    <CardImageContainer
                      className="fullHeight"
                      onClick={() =>
                        handleProductRoute(
                          data &&
                            data.getPopularProduct &&
                            data.getPopularProduct[0].product_slug
                        )
                      }
                    >
                      <img
                        src={getFirstImageUrl(
                          data.getPopularProduct &&
                            data.getPopularProduct[0].images
                        )}
                        alt="Image not found"
                      />
                    </CardImageContainer>
                  </Top>
                  <Bottom className="withbg">
                    <p
                      onClick={() =>
                        handleProductRoute(
                          data &&
                            data.getPopularProduct &&
                            data.getPopularProduct[0].product_slug
                        )
                      }
                    >
                      {data &&
                        data.getPopularProduct &&
                        data.getPopularProduct[0].product_name}
                    </p>
                    <Rating
                      name="hover-feedback"
                      value={
                        data &&
                        data.getPopularProduct &&
                        data.getPopularProduct[0].average_stars
                      }
                      precision={0.5}
                      // getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    <DescriptionButton>
                      <h3>
                        ₹{" "}
                        {data &&
                          data.getPopularProduct &&
                          data.getPopularProduct[0].sale_price}
                      </h3>
                      <CartBtn>
                        <button
                          onClick={() =>
                            handleAddCart(
                              data &&
                                data.getPopularProduct &&
                                data.getPopularProduct[0].product_id
                            )
                          }
                        >
                          <PiShoppingCartThin />
                        </button>
                      </CartBtn>
                    </DescriptionButton>
                  </Bottom>
                </Card>
              </Right>
              <Left>
                {data &&
                  data.getPopularProduct &&
                  data.getPopularProduct.slice(1, 7).map((item, index) => {
                    return (
                      <Card key={item.product_id}>
                        <Top>
                          <Like
                            onClick={() => handleAddToWishList(item.product_id)}
                          >
                            <CiHeart />
                          </Like>
                          <CardImageContainer
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            <img
                              src={producturl + item.thumbnail}
                              alt="Image not found"
                            />
                          </CardImageContainer>
                        </Top>
                        <Bottom className="withbg-right">
                          <p
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            {trimText(item.product_name)}
                          </p>
                          <Rating
                            name="hover-feedback"
                            value={item.average_stars}
                            precision={0.5}
                            // getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <DescriptionButton>
                            <h3>₹ {item.sale_price}</h3>
                            <CartBtn>
                              <button
                                className="green"
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <PiShoppingCartThin />
                              </button>
                            </CartBtn>
                          </DescriptionButton>
                        </Bottom>
                      </Card>
                    );
                  })}
              </Left>
            </PopularInner>
            <View>
              <button onClick={handleProduct}>View All</button>
            </View>
          </div>
          <MobileDeals>
            <div className="slider-container">
              <Slider {...Topsettings}>
                {data &&
                  data.getPopularProduct &&
                  data.getPopularProduct.map((item, index) => {
                    return (
                      <Card key={item.product_id}>
                        <Top>
                          <Like
                            onClick={() => handleAddToWishList(item.product_id)}
                          >
                            <CiHeart />
                          </Like>
                          <CardImageContainer
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            <img
                              src={producturl + item.thumbnail}
                              alt="Image not found"
                            />
                          </CardImageContainer>
                        </Top>
                        <Bottom className="withbg-right">
                          <p
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            {trimText(item.product_name)}
                          </p>
                          <Rating
                            name="hover-feedback"
                            value={item.average_stars}
                            precision={0.5}
                            // getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <DescriptionButton>
                            <h3>₹ {item.sale_price}</h3>
                            <CartBtn>
                              <button
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <PiShoppingCartThin />
                              </button>
                            </CartBtn>
                          </DescriptionButton>
                        </Bottom>
                      </Card>
                    );
                  })}
              </Slider>
            </div>
          </MobileDeals>
        </Popular>
        {/* Experience section end */}
        {/* ----------------------------------------------------- */}
        {/* ----------------------------------------------------- */}
        {/* Unveiling section start */}
        <Unveiling>
          <ImageContainer className="unveiling-banner ">
            <img src="/storage/general/banner1-2.png" alt="Image not found" />
          </ImageContainer>
          <div className="wrapper">
            <Container className="unveiling">
              <Left className="unveiling">
                <h3>Unveiling Ayurvedic Delights for the Day </h3>
                <p>
                  Discover the essence of holistic wellness with our curated
                  selection of Ayurvedic treasures. From rejuvenating skin care
                  to nourishing herbal supplements, embark on a journey of
                  self-care and vitality.
                </p>
                <button onClick={handleAyurvedha}>
                  <span>Shop Now</span>
                  <span>
                    <HiOutlineArrowRight />
                  </span>
                </button>
              </Left>
              <Right className="unveiling">
                <ImageContainer className="unveiling">
                  <img
                    src="/storage/general/banner1-3.png"
                    alt="Image not found"
                  />
                </ImageContainer>
              </Right>
            </Container>
          </div>
        </Unveiling>
        {/* Unveiling section end */}
        {/* ----------------------------------------------------- */}
        {/* ----------------------------------------------------- */}
        {/* Top Selling section start */}
        <TopSelling>
          <div className="wrapper">
            <h2>Top Selling</h2>
            <div className="slider-container">
              <Slider ref={sliderRef} {...Topsellingsettings}>
                {data &&
                  data.getTopSelling &&
                  data.getTopSelling.map((item, index) => {
                    return (
                      <Card key={item.product_id}>
                        <Top>
                          <Like
                            onClick={() => handleAddToWishList(item.product_id)}
                          >
                            <CiHeart />
                          </Like>
                          <CardImageContainer
                            className="top-selling"
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            <img
                              src={producturl + item.thumbnail}
                              alt="Image not found"
                            />
                          </CardImageContainer>
                        </Top>
                        <Bottom>
                          <p
                            onClick={() =>
                              handleProductRoute(item.product_slug)
                            }
                          >
                            {trimText(item.product_name)}
                          </p>
                          <Rating
                            name="hover-feedback"
                            value={item.average_stars}
                            precision={0.5}
                            // getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <DescriptionButton>
                            <h3>₹ {item.sale_price}</h3>
                            <CartBtn>
                              <button
                                className="green"
                                onClick={() => handleAddCart(item.product_id)}
                              >
                                <PiShoppingCartThin />
                              </button>
                            </CartBtn>
                          </DescriptionButton>
                        </Bottom>
                      </Card>
                    );
                  })}
              </Slider>
            </div>
            <ButtonContainer>
              <TopSellingButton onClick={handlePrev}>
                <HiOutlineArrowLeft />
              </TopSellingButton>
              <TopSellingButton onClick={handleNext}>
                <HiOutlineArrowRight />
              </TopSellingButton>
            </ButtonContainer>
          </div>
        </TopSelling>
        {/* Top Selling section end */}
        {/* ----------------------------------------------------- */}
        {/* Discover section start */}
        <Discover>
          <div className="wrapper">
            <Container className="discover">
              <Left className="discover">
                <h3>Discover the Soul of Kerala.</h3>
                <h5>
                  Experience Authentic Crafts, Handlooms & Ayurveda. Immerse
                  yourself in the rich heritage of Kerala.
                </h5>
                <p>Subscribe for our blogs...</p>
                <form onSubmit={handleSubscribe}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={subEmail}
                    onChange={(e) => setSubEmail(e.target.value)}
                  />
                  <button type="submit">Subscribe</button>
                </form>
              </Left>
              <Right className="discover">
                <ImageContainer className="discover">
                  <img
                    src="/storage/general/banner1.png"
                    alt="Image not found"
                  />
                </ImageContainer>
              </Right>
            </Container>
          </div>
        </Discover>
        {/* Discover section end */}
        {/* ----------------------------------------------------- */}
        {/* ----------------------------------------------------- */}
        {/* Experience section start */}
        <Experience className="bottom">
          <div className="wrapper">
            <Container className="experience">
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/delivery.svg" alt="delivery" />
                </ImageContainer>
                <h5>Home Delivery</h5>
              </Section>
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/experience.svg" alt="experience" />
                </ImageContainer>
                <h5>7+ Years Experience</h5>
              </Section>
              <Section className="experience">
                <ImageContainer className="experience">
                  <img src="/storage/general/people.svg" alt="people" />
                </ImageContainer>
                <h5>Customer Satisfaction</h5>
              </Section>
            </Container>
          </div>
        </Experience>
        {/* Experience section end */}
        {/* ----------------------------------------------------- */}
      </main>
    </>
  );
}
export default HomepageNew;
const Section = styled.section`
  // experience
  &.experience {
    display: grid;

    place-items: center;
    border-right: 1px solid #000;
    margin: 20px 0;
    padding: 20px 0;
    &:last-child {
      border: none;
    }
    h5 {
      margin-top: 20px;
      font-weight: 500;
      @media screen and (max-width: 640px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0;
      border-right: none;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
      margin: 0 20px;
      &:first-child {
        margin-top: 40px;
        @media screen and (max-width: 640px) {
          margin-top: 25px;
        }
      }
      &:last-child {
        margin-bottom: 40px;
        @media screen and (max-width: 640px) {
          margin-bottom: 20px;
        }
      }
    }
  }
  // deals
  // popular
  // unveiling
  // deals
  // topselling
  // discover
`;
const Container = styled.div`
  // experience
  &.experience {
    background-color: #fff5e8;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  // deals
  &.deals {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: start;
  }
  // popular
  // unveiling
  &.unveiling {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    align-items: start;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  &.discover {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 980px) {
      grid-template-columns: 1fr;
    }
  }
  // deals
  // topselling
  // discover
`;
const Hero = styled.div`
  @media screen and (max-width: 540px) {
    display: none;
  }
  .slider-container {
    max-height: 630px;

    @media screen and (max-width: 1024px) {
      max-height: 450px;
    }
    @media screen and (max-width: 768px) {
      max-height: 305px;
    }
  }
`;
const Experience = styled.div`
  padding: 30px 0;
  &.top {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.bottom {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
`;
const Deals = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  padding-top: 40px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 60px;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`;
const MobileDeals = styled.div`
  display: none;
  @media screen and (max-width: 640px) {
    display: block;
    padding-top: 40px;
    .slick-slide {
      max-width: 240px;
      padding-right: 5px;
    }
  }
`;
const Popular = styled.div`
  background-color: #ffedd4;
  h2 {
    padding-top: 40px;
    font-weight: 500;
    font-size: 24px;
  }
`;
const View = styled.button`
  display: grid;
  justify-items: end;
  width: 100%;
  background: none;
  border: none;
  padding-bottom: 30px;
  button {
    background-color: #fc9800;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 500;
  }
`;
const PopularInner = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  gap: 10px;
  padding-top: 40px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 60px;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`;
const Unveiling = styled.div`
  background-color: #084c27;
  padding: 60px 0;
  position: relative;
`;
const TopSelling = styled.div`
  padding: 60px 0;
  padding-bottom: 0;
  h2 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .slick-slide {
    padding-right: 5px;
    max-height: 400px;
  }
  .slick-slider {
    max-height: 400px;
  }
  .slider-container {
    max-height: 400px;
  }
`;
const Discover = styled.div`
  background-color: #ffedd4;
  padding: 60px 0;
`;
const Left = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  &.deals-left {
    border: 1px solid #ececec;
    padding: 10px;
    border-radius: 10px;
  }
  &.unveiling {
    gap: 20px;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: start;
    h3 {
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 600;
      color: #fff;
      @media screen and (max-width: 540px) {
        font-size: 25px;
      }
    }
    p {
      color: #fff;
      font-size: 18px;
      @media screen and (max-width: 540px) {
        font-size: 15px;
      }
    }
    button {
      background-color: #fc9800;
      color: #fff;
      border: none;
      border-radius: 10px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      font-size: 18px;
      svg {
        font-size: 20px;
        color: #fff;
      }
      @media screen and (max-width: 540px) {
        font-size: 15px;
        svg {
          font-size: 18px;
        }
      }
    }
  }
  &.discover {
    grid-template-columns: 1fr;

    h5 {
      font-weight: 500;
      margin-bottom: 20px;
    }
    h3 {
      font-weight: 600;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-size: 42px;
      @media screen and (max-width: 640px) {
        font-size: 30px;
      }
    }
    form {
      display: flex;
      width: 70%;
      margin: 20px 0;
      input {
        border-radius: 10px 0 0 10px;
      }
      button {
        border-radius: 0 10px 10px 0;
        height: 100%;
        padding: 10px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

const Card = styled.div`
  border: 1px solid rgb(229, 229, 229);
  border-radius: 10px;
  overflow: hidden;
  min-height: 320px;
  p {
    min-height: 75px;
  }
  &.fullHeight {
    height: 100%;
  }
`;
const Top = styled.div`
  position: relative;
  background-color: #fff;
`;
const Bottom = styled.div`
  padding: 10px 20px;
  border-radius: 0 0 10px 8px;

  &.withWhite {
    background-color: #f1f1f1;
  }
  &.withbg {
    background-color: #ad4b0e;
    p,
    h3 {
      color: #fff;
      font-size: 22px;
      padding: 10px 0;
      font-weight: 600;
    }
  }
  &.withbg-right {
    background-color: #ad4b0e;
    p,
    h3 {
      color: #fff;
      font-size: 15px;
      padding: 10px 0;
      font-weight: 400;
    }
  }
`;
const CartBtn = styled.div`
  button {
    border: none;
    padding: 10px;
    border-radius: 10px;
    &.green {
      background-color: #389763;
      svg {
        color: #fff;
      }
    }
    background-color: #fff;

    svg {
      color: #ad4b0e;
      font-size: 20px;
    }
  }
`;
const DescriptionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 18px;
    font-weight: 500;
  }
`;
const Like = styled.div`
  background-color: #efefef;
  padding: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 10px;
  svg {
    font-size: 22px;
    &:hover {
      color: red;
    }
  }
`;
const CardImageContainer = styled.div`
  height: 200px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    display: block;
  }
  @media screen and (max-width: 420px) {
    height: 150px;
    width: unset;
    img {
      object-fit: contain;
    }
  }
  &.top-selling {
    img {
      object-fit: cover;
    }
    @media screen and (max-width: 420px) {
      height: 200px;
      width: unset;
    }
  }
  &.fullHeight {
    height: calc(100% - 170px);
  }
`;
const Right = styled.div``;

const MobileHero = styled.div`
  display: none;
  @media screen and (max-width: 540px) {
    display: block;
  }
  .slider-container {
    @media screen and (max-width: 540px) {
      max-height: 540px;
    }
    @media screen and (max-width: 430px) {
      max-height: 560px;
    }
    @media screen and (max-width: 340px) {
      height: 420px;
    }
  }
`;
const Slide = styled.div`
  // hero
  &.hero {
    position: relative;

    button {
      position: absolute;
      bottom: 30%;
      left: 125px;
      @media screen and (max-width: 1024px) {
        left: 88px;
      }
      @media screen and (max-width: 768px) {
        left: 95px;
        bottom: 25%;
      }
      border: none;
      padding: 10px 20px;
      min-width: max-content;
      border-radius: 10px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      a,
      svg {
        font-size: 18px;
        color: white;
      }
    }
  }
  // experience
  // deals
  // popular
  // unveiling
  // deals
  // topselling
  // discover
`;
const MobileSlide = styled.div`
  // hero
  &.hero {
    position: relative;

    button {
      position: absolute;
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      border: none;
      padding: 10px 20px;
      width: 70%;
      border-radius: 10px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      a,
      svg {
        font-size: 18px;
        color: white;
      }
    }
  }
  // experience
  // deals
  // popular
  // unveiling
  // deals
  // topselling
  // discover
`;
const ImageContainer = styled.div`
  // hero
  &.hero {
    height: 630px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      height: 450px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      margin: auto;
      height: 305px;
    }
    @media screen and (max-width: 540px) {
      height: 540px;
    }
    @media screen and (max-width: 430px) {
      height: 560px;
    }
    @media screen and (max-width: 340px) {
      height: 420px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    @media screen and (max-width: 768px) {
      border-radius: 10px;
    }
  }
  // experience
  &.experience {
    background: #ad4b0e;
    height: 40px;
    width: 40px;
    padding: 7px;
    border-radius: 8px;
  }
  // deals
  // popular
  // unveiling
  &.unveiling-banner {
    position: absolute;
    left: 0;
    bottom: 0;
    img {
      height: 180px;
      width: 180px;
      object-fit: cover;
    }
  }
  &.unveiling {
    height: 340px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 540px) {
      height: 250px;
    }
  }
  // deals
  // topselling
  // discover
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 20px;
  padding-bottom: 40px;
`;
const TopSellingButton = styled.div`
  background-color: #084c27;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  svg {
    font-size: 20px;
    color: #fff;
  }
`;
